import { config, library, dom } from '@fortawesome/fontawesome-svg-core'

//  Change the config to fix the flicker
config.mutateApproach = 'sync'

import {
  faMask, faSignIn, faSignOut, faTrash, faPlus, faCheck, faTimes, faCalendarAlt, faAddressCard, faGuitar, faChurch, faUsers, faExclamationTriangle, faMapMarker, faClock, faBan, faPencil, faHourglass, faCheckCircle, faInfoCircle, faUnlink, faEye, faEyeSlash, faChevronLeft, faCloudArrowDown
} from "@fortawesome/pro-regular-svg-icons";

import {
  faInfoCircle as fasInfoCircle, faHouse as fasHouse
} from "@fortawesome/pro-solid-svg-icons";

library.add(
  faMask, faSignIn, faSignOut, faTrash, faPlus, faCheck, faTimes, faCalendarAlt, faAddressCard, faGuitar, faChurch, faUsers, faExclamationTriangle, faMapMarker, faClock, faBan, faPencil, faHourglass, faCheckCircle, faInfoCircle, faUnlink, faEye, faEyeSlash, faChevronLeft, faCloudArrowDown
);

library.add(
  fasInfoCircle, fasHouse
);

dom.watch()
